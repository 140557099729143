import "./App.css";

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 style={{ marginBottom: 10 }}>Study Material</h1>
        <p style={{ marginTop: 0 }}>Bachelor of Psychology (Honours)</p>
        <div class="links">
          <a class="btn-1" href={`PSY1617`} rel="noreferrer">
            PSY1617
          </a>
          <a class="btn-1" href={`PSY1622`} rel="noreferrer">
            PSY1622
          </a>
          <a class="btn-1" href={`PSY1610`} rel="noreferrer">
            PSY1610
          </a>
          <a class="btn-1" href={`PSY1606`} rel="noreferrer">
            PSY1606
          </a>
          <a class="btn-2" href={`PSY1620`} rel="noreferrer">
            PSY1620
          </a>
        </div>
      </header>
    </div>
  );
}

export default Home;
