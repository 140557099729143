import "./App.css";
import { useParams } from "react-router-dom";
import Quiz from "react-quiz-component";
import PSY1606 from "./units/PSY1606.json";
import PSY1620 from "./units/PSY1620.json";
import PSY1626 from "./units/PSY1626.json";
import PSY1610 from "./units/PSY1610.json";
import PSY1622 from "./units/PSY1622.json";
import PSY1617 from "./units/PSY1617.json";

const units = {
  PSY1606: PSY1606,
  PSY1620: PSY1620,
  PSY1626: PSY1626,
  PSY1610: PSY1610,
  PSY1622: PSY1622,
  PSY1617: PSY1617,
};

const youtube = {
  PSY1610:
    "https://youtube.com/playlist?list=PL9B3vhGQywfgjE1xfZH_br-7apMO3JLdC&si=otzzBh5VH6yFvzCj",
  PSY1622:
    "https://youtube.com/playlist?list=PL9B3vhGQywfhj4a_FWD_Rrk3gvL5uNnCV&si=HrYFSnizhjmeOmZW",
};

function App() {
  let { id } = useParams();
  if (Object.keys(units).includes(id)) {
    return (
      <div className="App">
        <header className="App-header">
          <Quiz quiz={units[id]} shuffle={true} continueTillCorrect={true} />
          <div class="links">
            <a
              class="btn-1"
              href={`/units/${id}.pdf`}
              target="_blank"
              rel="noreferrer"
            >
              Mindmap
            </a>
            <a
              class="btn-1"
              href={`/units/${id}.html`}
              target="_blank"
              rel="noreferrer"
            >
              Study Notes
            </a>

            {youtube[id] && (
              <a
                class="btn-1"
                href={youtube[id]}
                target="_blank"
                rel="noreferrer"
              >
                Youtube
              </a>
            )}
          </div>
        </header>
      </div>
    );
  } else {
    return (
      <div className="App">
        <header className="App-header">
          <h1>Not Found</h1>
        </header>
      </div>
    );
  }
}

export default App;
